@import './constants';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style-type: none;
}

// custom styles for scroll in select-dropdown
.rc-virtual-list-scrollbar {
  width: 6px !important;

  .rc-virtual-list-scrollbar-thumb {
    background-color: #cad3e0 !important;
  }
}

.ant-modal-root .ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.6);
}

.ant-modal-wrap {
  backdrop-filter: blur(3px);
}

// rotate arrow in antd selects
.ant-select-arrow {
  transition: transform 0.2s ease-in !important;
}

.ant-select-open {
  .ant-select-arrow {
    transform: rotate(-180deg);
  }
}

.ant-select .anticon {
  pointer-events: none !important; // the style was rewritten because the icon was not clickable
}

// custom styles for message
.ant-notification-notice-message {
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.21px;
}

// styles for error messages (Antd form items)
.ant-form-item-explain-error {
  margin: 4px 0 24px 0;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.048px;

  @media screen and (max-width: 768px) {
    margin: 4px 0 16px 0;
  }
}
