.slider-range__popup_overlay.left_correct .ant-popover-arrow {
  left: 3px !important;
}
.slider-range__popup_overlay.right_correct .ant-popover-arrow {
  right: 3px !important;
}

.slider-range__popup_overlay.ant-popover .ant-popover-inner {
  box-shadow: var(--tooltip-box-shadow);
}
