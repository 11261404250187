:root {
  // fonts

  --main-font-family: 'Open Sans', sans-serif;
  --big-title-size: 20px;
  --mobile-title-size: 16px;
  --desktop-content-font-size: 16px;
  --main-font-size: 14px;
  --tablet-description-font-size: 14px;
  --mobile-description-font-size: 14px;
  --bold-font-size: 18px;
  --section-title-desktop-font-size: 18px;
  --price-description-desktop: 12px;
  --content-mobile-size: 12px;
  --button-desktop-font-size: 12px;
  --price-big-desktop: 24px;
  --price-small-desktop: 18px;
  --price-big-mobile: 16px;
  --price-small-mobile: 14px;
  --main-font-weight: 400;
  --medium-font-weight: 500;
  --bold-font-weight: 600;
  --extra-bold-font-weight: 700;
  --font-style-base: normal;
  --font-style-title: bold;

  --letter-spacing-desktop: 0.024px;

  --desktop-letter-spacing: 0.21px;
  --mobile-letter-spacing: 0.18px;
  --mobile-title-letter-spacing: 0.08px;
  --desktop-title-letter-spacing: 0.027px;
  --desktop-price-title-letter-spacing: 0.048px;
  --desktop-content-line-height: 140%;
  --content-desktop-line-height: 133%;
  --mobile-line-height: 133%;
  --title-account-line-height: 120%;

  // colors

  --Dark: #262626;
  --Dark-13: #192a4d;
  --White: #fff;
  --Blue-13: #2b3e64;
  --Blue-12: #003891;
  --Blue-8: #1b79de;
  --Blue-7: #3f8bf3;
  --Red-1: #ffebeb;
  --Gray-100: #181b21;
  --Gray-9: #393f4a;
  --Gray-7: #717c8c;
  --Gray-6: #97a3b5;
  --Gray-5: #afbbcc;
  --Gray-4: #cad3e0;
  --Gray-3: #e1e7f0;
  --Gray-2: #f5f7fa;
  --Gray-1: #fcfcfc;

  --card-stroke-18: rgba(113, 124, 140, 0.18);

  // shadows

  --tooltip-box-shadow: 0px 0.3px 0.5px 0px rgba(113, 124, 140, 0.1),
    0px 2px 4px 0px rgba(113, 124, 140, 0.2);
  --popover-icon-box-shadow: 0px 2px 8px 0px rgba(0, 42, 126, 0.1);
  --popover-header-user-info-shadow: 0px 0.4px 0.8px 0px rgba(80, 89, 102, 0.1),
    0px 3px 6px 0px rgba(80, 89, 102, 0.2);
  --user-options-drawer-shadow: 0px 0.1px 0.3px 0px rgba(113, 124, 140, 0.1),
    0px 1px 2px 0px rgba(113, 124, 140, 0.1);
  --account-content-shadow: 0px -1px 0px 0px #e1e7f0 inset;

  //gaps
  --gap-header-nav-desktop: 24px;
  --gap-header-nav-vertical-desktop: 24px;
  --gap-header-nav-before-tablet: 14px;
  --gap-desktop-row-small: 8px;
  --gap-user-avatar-desktop: 8px;
  --gap-action-buttons-desktop: 16px;
  --gap-account-form-blocks-desktop: 24px;
  --gap-account-form-items-desktop: 24px;

  //paddings, margins
  --padding-profile-content-desktop: 24px;
  --padding-profile-content-mobile: 16px;
  --padding-title-content-desktop: 24px;
  --padding-title-content-mobile: 24px 16px;
  --padding-feature-tag-desktop: 8px;

  // frames width

  --max-width-form-turnkey-desktop: calc(100% - 200px);
  --max-width-form-turnkey-tablet: calc(100% - 48px);
  --max-width-form-turnkey-mobile: calc(100% - 32px);
}
